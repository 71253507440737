import React from "react";
import Container from '@material-ui/core/Container';

class LoadingScreen extends React.Component {
    render() {
        return (
            <Container>
                <h1> PLEASE WAIT!</h1>
                <br/>
                <body1>Keep an eye on the screen! You're good for now</body1>
            </Container>
        );
    }
}

export default LoadingScreen;

import React from "react";
import SelectableOption from "./SelectableOption";
import * as Consts from "../Consts";
import Grid from '@material-ui/core/Grid';


class DoubleAnswersScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstSelection: null,
            secondSelection: null
        };
        this.handleSelection = this.handleSelection.bind(this);
    }

    handleSelection(index) {
        if(this.props.socket.readyState !== 1)
            return;

        if (this.state.firstSelection == null) {
            this.setState({ firstSelection: index });
        } else {
            this.setState({ secondSelection: index }, () => {
                // Send the choices back to the server
                let result = JSON.stringify({
                    messageType: Consts.ON_DOUBLE_ANSWERS_RECEIVED,
                    "SELECTED_DOUBLE_ANSWERS": [this.state.firstSelection, this.state.secondSelection],
                    userUID: this.props.userID,
                    roomCode: this.props.roomCode
                });
                console.log(result);
                this.props.socket.send(result);
                this.props.callback()
            });
        }
    }

    render() {
        return (
            <div>
                <h2>Choose two answers.</h2>
                <h1>{this.props.title}</h1>
                <Grid container direction="column"
                      justifyContent="center"
                      alignItems="center" spacing={3}>
                    {this.props.options.map((item, index) => {
                        const disabled = index === this.state.firstSelection;
                        return (
                            <Grid item>
                                <SelectableOption
                                    key={index}
                                    index={index}
                                    text={item}
                                    onClick={() => this.handleSelection(index)}
                                    callBack={this.handleSelection}
                                    disabled={disabled}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        );
    }
}

export default DoubleAnswersScreen;

import React from "react";
import * as Consts from '../Consts'
import {Button} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

class LoginScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {name: '', roomCode: '', enabled: true};
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleRoomCodeChange = this.handleRoomCodeChange.bind(this);
        this.props.socket.addEventListener('open', () => {
            this.setState({enabled: true})
        });
        this.props.socket.addEventListener('close', () => {
            this.setState({enabled: false})
        });
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isConnected = this.isConnected.bind(this);

    }

    handleNameChange(event) {
        const name = event.target.value;
        // if (name.length <= 12)
        // {
        //     this.setState({name: name});
        // }
        // else
        // {
        //     alert("Name cannot be longer than 12 characters.");
        // }

        // if (!name.length > 0)
        // {
        //     alert("Name cannot be empty");
        // }
        // else
        // {
        //     this.setState({name: name});
        // }
        this.setState({name: event.target.value});
    }

    handleRoomCodeChange(event) {
        this.setState({roomCode: event.target.value});
    }

    handleSubmit() {
        let uid = localStorage.getItem('uid');

        this.props.socket.send(JSON.stringify({
            messageType: Consts.LOGIN,
            username: this.state.name,
            userUID: uid,
            roomCode: this.state.roomCode
        }));
        this.props.callback()
    }

    isConnected() {
        return (this.props.socket.readyState === 1);
    }

    render() {
        var isEnabled = this.isConnected();
        return (
            <Grid container
                  direction="column"
                  justifyContent="center"
                  alignItems="center" spacing={3}>
                <Grid item><h1>Welcome to Quiz Quest!</h1></Grid>
                <Grid item>
                    <TextField label="Name" inputProps={{maxLength: 12, minLength: 1, style: {textAlign: 'center'}}} onChange={this.handleNameChange}
                               variant="filled" value={this.state.name}/>
                </Grid>
                <Grid item>
                    <TextField label="Quest Code" inputProps={{style: {textAlign: 'center'}}}
                               onChange={this.handleRoomCodeChange} variant="filled"
                               value={this.state.roomCode}/>
                </Grid>
                <Grid item>
                    <Button size="large" variant="contained" color="primary" onClick={() => {
                        this.handleSubmit()
                    }} disabled={!isEnabled}>Join Quest</Button>
                </Grid>
            </Grid>
        );
    }
}

export default LoginScreen;

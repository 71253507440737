import React from "react";
import SelectableOption from "./SelectableOption";
import * as Consts from "../Consts";
import Grid from '@material-ui/core/Grid';

class SelectOptionScreen extends React.Component {

    constructor(props) {
        super(props);
        this.buttonCallback = this.buttonCallback.bind(this)
    }

    buttonCallback(index) {
        if(this.props.socket.readyState !== 1)
            return
        let result = JSON.stringify({
            messageType: Consts.PLAYER_SELECTION_MADE,
            selectedOption: index,
            userUID:this.props.userID,
            roomCode:this.props.roomCode
        })
        console.log(result)
        this.props.socket.send(result);
        this.props.callback()
    }

    isOptionDisabled(item) {
        // This function checks if the text of an option ends with "([0])"
        // which indicates zero remaining uses.
        return item.endsWith("([0])");
    }

    renderTitleWithHints(title) {
        // Split the title into segments
        const titleParts = title.split("(HINT)");
        return (
            <div>
                {titleParts.map((part, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && <h2>(HINT)</h2>} {/* Render "(HINT)" before every part except the first one */}
                        <p>{part}</p> {/* Render the part of the title */}
                    </React.Fragment>
                ))}
            </div>
        );
    }

    render() {
        return (
            <div>
                <h1>
                    {this.renderTitleWithHints(this.props.title)}
                    {/*{this.props.title}*/}
                </h1>

                <Grid container
                      direction="column"
                      justifyContent="center"
                      alignItems="center" spacing={3}>
                    {this.props.options.map((item, index) => {
                        const isDisabled = item.includes("([0])");

                        return <Grid item>
                            <SelectableOption key={index}
                                              index={index}
                                              // onClick={() => this.buttonCallback(index)}
                                              onClick={() => !isDisabled && this.buttonCallback(index)}
                                              disabled={isDisabled}
                                              // disabled={this.isOptionDisabled()} // Or any other logic you have for disabling
                                              // text={isDisabled ? item.replace("([0])", "").trim() : item} // Remove "([0])" from the display text
                                              text={item}
                                              callBack={this.buttonCallback}/>
                        </Grid>
                    })}
                </Grid>
            </div>
        );
    }
}

export default SelectOptionScreen;

import React from "react";
import Container from '@material-ui/core/Container';

class ReconnectedPlayer extends React.Component {
    render() {
        return (
            <Container>
                <h1> RECONNECTING...</h1>
                <br />
                <body1>Whoops! Lost you for a moment there! Please wait for the next question.</body1>
            </Container>
        );
    }
}

export default ReconnectedPlayer;

import React from "react";
import Container from '@material-ui/core/Container';

class KnockedOut extends React.Component {
    render() {
        return (
            <Container>
                <h1>Knocked out!</h1>
                <br />
                <body1>Ouch! That’s gotta hurt! Please wait for the round to end...</body1>
            </Container>
        );
    }
}

export default KnockedOut;

import React from "react";
import {Button, Container} from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class CurrentStateDebug extends React.Component {
    constructor(props) {
        super(props);
        this.state = {connectedLevel: 0, lastMessage: '', retryCount: 0};

        this.props.socket.addEventListener('message', (event) => {
            this.setState({lastMessage: event.data})
        });
        this.checkSocketState = this.checkSocketState.bind(this)
    }

    checkSocketState() {
        this.setState({connectedLevel: this.props.socket.readyState, retryCount: this.props.socket.retryCount})
    }

    render() {
        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h2>Debug Settings</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <Container>

                        Screen: {this.props.currentScreen} <br/>
                        Roomcode: {this.props.roomCode}<br/>
                        userID: {this.props.userID} <br/>
                        Current Connected Level: {this.state.connectedLevel}<br/>
                        Retries Done: {this.state.retryCount}<br/>
                        Last message recieved: {this.state.lastMessage} <br/>
                        Last message hash: {this.props.lastMessageHash} <br/>

                        <Button variant="contained" color="primary" onClick={() => {
                            this.checkSocketState()
                        }}>UpdateSocketState[DEBUG]</Button>
                        <Button variant="contained" color="primary" onClick={() => {
                            this.props.socket.reconnect()
                        }}>
                            RECONNECT SOCKET
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => {
                            this.props.socket.close()
                        }}>
                            CLOSE SOCKET
                        </Button>
                    </Container>
                </AccordionDetails>
            </Accordion>
        )
    }
}

export default CurrentStateDebug;

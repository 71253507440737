import React from "react";
import Container from '@material-ui/core/Container';

class TimedOut extends React.Component {
    render() {
        return (
            <Container>
                <h1>Frozen!</h1>
                <br />
                <body1>Ouch! You've been frozen! Wait a jiffy before answering again!</body1>
            </Container>
        );
    }
}

export default TimedOut;

import React from "react";
import Container from '@material-ui/core/Container';

class RoomFull extends React.Component {
    render() {
        return (
            <Container>
                <h1> ROOM FULL!</h1>
                <br />
                <body1>The current room is full. Please join a different room.</body1>
            </Container>
        );
    }
}

export default RoomFull;

import React from "react";
import SelectableOption from "./SelectableOption";
import Grid from '@material-ui/core/Grid';

class CorrectAnswerScreen extends React.Component {
    render() {
        const { options, title, selectedOptions, correctIndex, displaySleeves } = this.props;
        const isMultiAnswer = Array.isArray(selectedOptions);
        console.error("SelectedOptions:" + selectedOptions);
        const bonusText = "x2";
        const minusText = "-1";
        return (
            <div>
                <h1>{title}</h1>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
                    {options.map((option, index) => {
                        const isSelected = isMultiAnswer ? selectedOptions.includes(index) : index === selectedOptions;
                        const isCorrect = index === correctIndex;
                        const isIncorrect = isSelected && !isCorrect;
                        let showSleeves = displaySleeves;
                        let sleevesText = "";
                        if (showSleeves)
                        {
                            if (isSelected)
                            {
                                if (isCorrect)
                                    sleevesText = bonusText;
                                else if (isIncorrect)
                                    sleevesText = minusText;
                            }

                        }
                        console.log("Sleeves Text: " + sleevesText);
                        console.log("Display Bonus: " + showSleeves);

                        return (
                            <Grid item key={index}>
                                <SelectableOption
                                    text={option}
                                    isSelected={isSelected}
                                    isCorrect={isCorrect}
                                    isIncorrect={isIncorrect}
                                    displaySleeves = {displaySleeves}
                                    sleevesText = {sleevesText}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        );
    }
}

export default CorrectAnswerScreen;
